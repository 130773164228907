/* eslint import/newline-after-import:0 import/first:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../initializers/polyfill_closest';

import 'jquery-ujs';
import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/localization/messages_ja';
import '../vendor/jquery.autoKana';
import '../initializers/validates';
import '../initializers/exists';
import '../initializers/prevent_enter_submit';
import '../initializers/bugsnag';
import '../initializers/submit_handler';
import '../initializers/autokana';

require('../rp/validates');
require('../rp/validates/entries');

require('../rp/photo_stacks');
require('../rp/prefecture_toggle');
require('../rp/side_container_toggle');
