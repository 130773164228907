// 画面自体のスクロールを許可/拒否する
function scrollControl(state) {
  if (state) {
    $('body').css('position', 'static').css('width', '');
  } else {
    $('body').css('position', 'fixed').css('width', '100%');
  }
}

export default scrollControl;
