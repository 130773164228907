import scrollControl from '../lib/scroll_control';

$(() => {
  // モーダルの表示処理
  function modalShow($el) {
    $el.show(0, () => $el.css('transform', 'translateX(0)'));
  }

  // モーダルの非表示処理
  function modalHide($el) {
    $el.css('transform', '');
    $el.on('transitionend', () => {
      $el.hide();
      scrollControl(true);
      $el.off('transitionend');
    });
  }

  $('.js-side-container-toggle').on('click', () => {
    const $target = $('.js-side-container');

    if ($target.is(':visible')) {
      modalHide($target);
    } else {
      modalShow($target);
      scrollControl(false);
    }
  });
});
