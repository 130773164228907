$(() => {
  $('.js-prefecture-toggle').on('click', e => {
    const $toggle = $(e.currentTarget);
    const $target = $('.js-prefecture-body');

    if ($target.is(':visible')) {
      $target.hide('fast');
      $toggle.removeClass('active');
    } else {
      $target.show('fast');
      $toggle.addClass('active');
    }
  });
});
